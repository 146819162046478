<template>
  <div class="box-content">
    <mcp-modal :show="show" @closeModal="showModal">
      <customer-add
        @closeModal="showModal"
        :callBackFunction="refreshService"
        :v="info"
      />
    </mcp-modal>
    <mcp-modal :show="showEdit" @closeModal="showModalEdit">
      <customer-update
        @closeModal="showModalEdit"
        :callBackFunction="refreshService"
        :model="current"
        :v="info"
      />
    </mcp-modal>
    <search :makeSearch="makeSearch">
      <template #buttons>
        <button
          @click="show = true"
          v-if="isMcp"
          class="button is-outlined is-small"
        >
          Ajouter un service
        </button>
        <router-link
          v-if="!isMcp"
          class="button is-outlined is-small"
          to="/services/create"
        >
          Ajouter un service
        </router-link>
        <button @click="refreshService" class="button is-outlined is-small">
          Actualiser
        </button>
      </template>
    </search>
    <data-table
      :columns="columns"
      :loading="loading"
      :counter="services.length"
      :meta="meta"
      :navigationFunction="navigationFunction"
    >
      <template #body>
        <tbody>
          <tr v-for="(item, key) in services" :key="key">
            <td>{{ item.service.replace(/-/g, " ") }}</td>
            <td>{{ item.service }}</td>
            <td v-if="!isMcp">{{ item.email }}</td>
            <td v-if="!isMcp">{{ item.phone }}</td>
            <td>
              <span v-if="item.active" class="tag is-success is-light"
                >Actif</span
              >
              <span v-else class="tag is-primary is-light">Inactif</span>
            </td>
            <td>{{ item.createdate }}</td>
            <td>
              <div class="d-flex">
                <mcp-tooltip v-if="!isMcp" title="Liste des souscriptions">
                  <i
                    class="fa-solid fa-info"
                    @click="
                      () =>
                        $router.push({
                          name: 'souscriptions',
                          params: { id: item.id, service: item.service },
                        })
                    "
                  ></i>
                </mcp-tooltip>
                <mcp-tooltip v-if="isMcp" title="Modifier un service">
                  <i @click="showEditForm(item)" class="fa-solid fa-edit"></i>
                </mcp-tooltip>
                <mcp-tooltip title="Modifier un service">
                  <i
                    v-if="!isMcp"
                    @click="
                      () =>
                        $router.push({
                          name: 'services_edit',
                          params: { id: item.id },
                        })
                    "
                    class="fa-solid fa-edit"
                  ></i>
                </mcp-tooltip>
                <mcp-tooltip title="Supprimer un service">
                  <i
                    @click="removeService(item)"
                    class="fa-solid fa-remove"
                  ></i>
                </mcp-tooltip>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </data-table>
  </div>
</template>

<script>
import dataTable from "@/components/table/datatable";
import search from "@/components/table/search";
import { TYPES } from "@/store/types";
import { mapGetters } from "vuex";
import mcpModal from "@/components/McpModal";
import customerAdd from "./customerAdd.vue";
import customerUpdate from "./customerUpdate.vue";
import { isMcp } from "@/utils/Auth";
import { EventBus } from "@/utils/EventBus";
const m = isMcp();
export default {
  name: "services",
  components: {
    dataTable,
    search,
    mcpModal,
    customerAdd,
    customerUpdate,
  },
  data: () => ({
    isMcp: null,
    loading: false,
    show: false,
    showEdit: false,
    columns: [
      { name: "service", label: "Nom du service" },
      { name: "code", label: "Code du service" },
      { name: "email", label: "Email du client", state: m },
      { name: "phone", label: "Contact du client", state: m },
      { name: "etat", label: "Etat du service" },
      { name: "created_at", label: "Date de création" },
      { name: "action", label: "Actions" },
    ],
    model: {
      page: 1,
      search: "",
    },
    current: {},
    info: { v: 0, p: 0 },
  }),

  computed: {
    ...mapGetters({
      services: `${TYPES.modules.SERVICES}/${TYPES.getters.GET_SERVICES}`,
      meta: `${TYPES.modules.SERVICES}/${TYPES.getters.GET_SERVICES_META}`,
      service: `${TYPES.modules.SERVICES}/${TYPES.getters.GET_SERVICE}`,
    }),
  },

  mounted() {
    this.isMcp = this.$isMcp();
    this.getServices();
    EventBus.$on("auth", this.currentAuth);
  },

  methods: {
    currentAuth(user) {
      this.info = user;
    },
    navigationFunction(page) {
      this.model.page = page;
      this.getServices();
    },

    getServices() {
      this.loading = true;
      this.$store
        .dispatch(
          `${TYPES.modules.SERVICES + "/" + TYPES.actions.GET_SERVICES}`,
          this.model
        )
        .then((res) => {
          if (res) this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    makeSearch(search) {
      this.model.page = 1;
      this.loading = true;
      this.model.search = search;
      this.$store
        .dispatch(
          `${TYPES.modules.SERVICES + "/" + TYPES.actions.GET_SERVICES}`,
          this.model
        )
        .then((res) => {
          if (res) this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    refreshService() {
      this.loading = true;
      this.model.page = 1;
      this.model.search = "";
      this.$store
        .dispatch(
          `${TYPES.modules.SERVICES + "/" + TYPES.actions.GET_SERVICES}`,
          this.model
        )
        .then((res) => {
          if (res) this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    removeService(service) {
      if (confirm("Voulez vous supprimer le service ?")) {
        this.loading = true;
        this.$store
          .dispatch(
            `${TYPES.modules.SERVICES + "/" + TYPES.actions.DELETE_SERVICE}`,
            service
          )
          .then((res) => {
            if (res) {
              this.loading = false;
              this.$toast.success(`Le service a été supprimé`);
            }
          })
          .catch((error) => {
            this.loading = false;
          });
      }
    },

    showModal() {
      this.show = !this.show;
    },

    showModalEdit() {
      this.showEdit = !this.showEdit;
    },

    showEditForm(model) {
      this.current = model;
      this.showModalEdit();
    },

    getService(service) {
      this.$store
        .dispatch(
          `${TYPES.modules.SERVICES + "/" + TYPES.actions.DETAILS_SERVICE}`,
          service.id
        )
        .then(() => {})
        .catch(() => {});
    },

    detailService(service) {
      this.getBnak(service);
      this.showModal();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>