<template>
  <div>
    <textarea
      :id="id"
      ref="input"
      v-bind="$attrs"
      :value="value"
      @input="$emit('input', $event.target.value)"
      :class="errors.length ? 'textarea is-danger' : 'textarea'"
    ></textarea>
    <div v-if="errors.length" :class="errors.length ? 'invalid-feedback' : ''">
      <strong class="has-text-danger" style="font-size: 12px">{{
        errors[0]
      }}</strong>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextAreaInput",
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default() {
        return `text-input-${this._uid}`;
      },
    },
    value: String,
    label: String,
    errors: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>