<template>
  <div>
    <h2>Ajouter un service</h2>
    <mcp-spinner v-if="loading" :show="true" color="#CF0C1A" />
    <div v-else>
      <div class="v_error pt-10" v-if="v.v === 0">
        Aucune action n'est possible tant que votre compte n'a pas été activé.
      </div>
      <div class="pt-10">
        <text-input
          :errors="errors.service"
          v-model="form.service"
          label="Nom du service"
        />
      </div>
      <div class="pt-10">
        <label>Opérateurs</label>
        <v-select
          :options="operators"
          multiple
          placeholder="Selectionner"
          v-model="form.operator"
          label="bankcode"
          :reduce="(item) => item.id"
        ></v-select>
        <div
          v-if="errors.operator"
          :class="errors.operator.length > 0 ? 'invalid-feedback' : ''"
        >
          <strong class="has-text-danger" style="font-size: 12px">{{
            errors.operator[0]
          }}</strong>
        </div>
      </div>
      <div class="pt-10">
        <text-area-input
          :errors="errors.description"
          v-model="form.description"
          label="Description du service"
        />
      </div>
      <div class="pt-10">
        <label class="checkbox"
          ><input v-model="form.active" type="checkbox" /> Activer le
          service</label
        >
      </div>
      <div class="pt-10 d-flex-right">
        <button
          @click="addService"
          :disabled="loadingBtn || v.v === 0"
          :class="
            loadingBtn
              ? 'button is-responsive is-loading is-primary'
              : 'button is-responsive is-primary'
          "
        >
          Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { TYPES } from "@/store/types";
import { mapGetters } from "vuex";
import TextInput from "@/components/TextInput";
import TextAreaInput from "@/components/TextAreaInput";
import { coreConfig, mcpId } from "@/utils/Auth";
export default {
  name: "addService",
  components: { TextInput, TextAreaInput },
  props: {
    callBackFunction: {
      type: Function,
      default: () => {},
    },
    v: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    loading: false,
    loadingBtn: false,
    form: {
      service: "",
      active: true,
      description: "",
      customer_id: "",
      rating: "",
      operator: [],
      permissions: [],
    },
    errors: [],
  }),
  computed: {
    ...mapGetters({
      operators: `${TYPES.modules.UTILS}/${TYPES.getters.GET_OPERATOR}`,
    }),
  },
  mounted() {
    this.getOperators();
  },
  methods: {
    getOperators() {
      this.loading = true;
      this.$store
        .dispatch(`${TYPES.modules.UTILS + "/" + TYPES.actions.GET_OPERATOR}`)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    getPermissions() {
      const permissions = coreConfig()
        .allow_core_permissions.split(",")
        .map((x) => +x);
      return permissions;
    },

    getRating() {
      return coreConfig().allow_core_rating;
    },

    addService() {
      if (this.v.v === 1) {
        this.loadingBtn = true;
        this.form.permissions = this.getPermissions();
        this.form.rating = this.getRating();
        this.form.customer_id = mcpId();
        this.$store
          .dispatch(
            `${TYPES.modules.SERVICES + "/" + TYPES.actions.ADD_SERVICE}`,
            this.form
          )
          .then((res) => {
            this.loadingBtn = false;
            this.errors = [];
            this.$emit("closeModal");
            this.callBackFunction();
            this.$toast.success(`Le service a été ajouté`);
          })
          .catch((error) => {
            this.loadingBtn = false;
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
            }
          });
      } else
        this.$toast.error(
          `Aucune action n'est possible tant que votre compte n'a pas été activé.`
        );
    },
  },
};
</script>